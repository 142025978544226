import React from 'react';
import { push } from 'gatsby';
import { Row, Column, Toolbar, NavLink, Text } from 'rebass/emotion';
import styled, { css } from 'react-emotion';
import theme from '../theme';
import style, { animatedUnderline, underline } from '../utils/style';
import LogoText from './LogoText';

const changeOrientation = css`
  ${style.mediaQueries.maxTablet} {
    && {
      flex-direction: column;
    }
  }
`;

const headerStyles = {
  default: {
    py: ['1em'],
    mx: [0],
    bg: '#4CCCFF',
  },
};

const LinkText = styled(Text)(
  props => ({
    fontFamily: theme.fonts.display,
    fontWeight: 400,
    color: 'white',
    transition: 'border-color 0.1s',
    borderWidth: '0px',
    textTransform: 'capitalize',
    '&:hover': animatedUnderline,
  }),
  animatedUnderline,
  props => props.isActive && underline
);

const MenuLink = ({ children, toggleModal }) => {
  return (
    <NavLink
      onClick={toggleModal}
      ml={[-4, 'auto']}
      mr={[-3, 0]}
      fontSize={[3, 3]}
      css={{ alignSelf: 'center' }}>
      <LinkText pt={[0, '20px']} pb={[0, '5px']} px={1}>
        {children}
      </LinkText>
    </NavLink>
  );
};

const Footer = ({ toggleModal }) => {
  const pageStyle = { ...headerStyles.default };
  return (
    <Row {...pageStyle}>
      <Column css={{ paddingLeft: '8px', maxWidth: '1200px', margin: '0 auto' }} mb={0}>
        <Toolbar className={changeOrientation} bg="transparent">
          <NavLink
            onClick={() => push('/')}
            w={[1 / 2, 1]}
            pl={[3, 4]}
            ml={[-4, -3]}
            color={'white'}
            css={{ alignSelf: 'center', fontFamily: theme.fonts.display, fontWeight: 400 }}
            fontSize={[5, '40px', 6]}>
            <LogoText />
          </NavLink>
          <MenuLink children={'Pricing'} toggleModal={toggleModal} />
          <MenuLink children={'About'} toggleModal={toggleModal} />
          <MenuLink children={'Privacy'} toggleModal={toggleModal} />
          <MenuLink children={'Terms'} toggleModal={toggleModal} />
        </Toolbar>
      </Column>
    </Row>
  );
};

export default Footer;
