import React from 'react';
import { Collapse } from '@blueprintjs/core';
import { MdMenu } from 'react-icons/md';
import { NavLink, Button, Divider } from 'rebass/emotion';
import { css } from 'react-emotion';
import '../../css/lib/blueprint.css';
import style from '../../utils/style';

const toggleMenu = css`
  display: none;
  ${style.mediaQueries.maxTablet} {
    && {
      display: flex;
    }
  }
`;

const MobileMenu = ({ isOpen, handleClick, toggleModal, LinkText }) => {
  return (
    <div
      css={{ marginLeft: 'auto', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}
      className={toggleMenu}>
      <Button bg={'#4CCCFF'} mr={-3} onClick={handleClick}>
        <MdMenu css={{ opacity: '0.8' }} size={40} />
      </Button>
      <Collapse css={{ width: '100%', textAlign: 'end' }} isOpen={isOpen}>
        <NavLink
          onClick={toggleModal}
          css={`
            &:hover {
              text-decoration: none;
            }
          `}
          pb={1}
          mr={[-2, 0]}
          fontSize={[3, 3]}>
          <LinkText css={{ textDecoration: 'none' }} px={1}>
            Docs
          </LinkText>
        </NavLink>
        <Divider css={{ opacity: '0.4' }} py={0} my={0} borderBottom={2} borderColor={'white'} />
        <NavLink
          onClick={toggleModal}
          css={`
            &:hover {
              text-decoration: none;
            }
          `}
          pb={1}
          mr={[-2, 0]}
          fontSize={[3, 3]}>
          <LinkText px={1}>Pricing</LinkText>
        </NavLink>
        <Divider css={{ opacity: '0.4' }} py={0} my={0} borderBottom={2} borderColor={'white'} />
        <NavLink
          onClick={toggleModal}
          css={`
            &:hover {
              text-decoration: none;
            }
          `}
          pb={1}
          mr={[-2, 0]}
          fontSize={[3, 3]}>
          <LinkText px={1}>About</LinkText>
        </NavLink>
        <Divider css={{ opacity: '0.4' }} py={0} my={0} borderBottom={2} borderColor={'white'} />
        <NavLink
          onClick={toggleModal}
          css={`
            &:hover {
              text-decoration: none;
            }
          `}
          pb={1}
          mr={[-2, 0]}
          fontSize={[3, 3]}>
          <LinkText px={1}>Sign In</LinkText>
        </NavLink>
        <Divider
          css={{ opacity: '0.4', marginBottom: '10px' }}
          pb={0}
          my={0}
          borderBottom={2}
          borderColor={'white'}
        />
      </Collapse>
    </div>
  );
};

export default MobileMenu;
