import React from 'react';
import logo from '../assets/logo.svg';
import { Image } from 'rebass/emotion';

const LogoText = () => {
  return (
    <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Image css={{ minWidth: '52px' }} pr={2} m={0} src={logo} />
      Scopana
    </div>
  );
};

export default LogoText;
