import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'gatsby';
import { Row, Column, Toolbar, NavLink, Text, Button } from 'rebass/emotion';
import styled, { css } from 'react-emotion';
import theme from '../../theme';
import style, { animatedUnderline, underline } from '../../utils/style';
import MobileMenu from './MobileMenu';
import LogoText from '../LogoText';

const flipDisplay = css`
  margin-left: auto;
  ${style.mediaQueries.maxTablet} {
    && {
      display: none;
    }
  }
`;

const buttonStyle = css({
  fontSize: '20px',
  textTransform: 'capitalize',
  transition: 'all 0.1s',
  cursor: 'pointer',
  borderRadius: '4px',
  '&:focus': {
    boxShadow: 'none',
  },
  '&:hover': {
    transform: `translateY(-2px) scale(1.01, 1.01)`,
    borderRadius: '4px',
    boxShadow: style.shadows.high,
  },
  '&:active': {
    boxShadow: style.shadows.low,
    transform: `translateY(0) scale(1.0, 1.0)`,
    borderRadius: '4px',
    transition: `transform 50ms`,
  },
});

const headerStyles = {
  default: {
    py: ['1em'],
    mx: [0],
    bg: '#4CCCFF',
  },
};

const LinkText = styled(Text)(
  props => ({
    fontFamily: theme.fonts.display,
    fontWeight: 400,
    color: 'white',
    transition: 'border-color 0.1s',
    borderWidth: '0px',
    textTransform: 'capitalize',
    '&:hover': animatedUnderline,
  }),
  animatedUnderline,
  props => props.isActive && underline
);

const MenuLink = ({ children, toggleModal }) => {
  return (
    <NavLink onClick={toggleModal} ml="auto" mr={[-3, 0]} fontSize={[3, 3]}>
      <LinkText pt={['15px', '20px']} pb={['5px']} px={1}>
        {children}
      </LinkText>
    </NavLink>
  );
};

class Header extends React.Component {
  state = {
    isOpen: false,
  };

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const toggleModal = this.props.toggleModal;
    const pageStyle = { ...headerStyles.default };
    return (
      <Row {...pageStyle}>
        <Column css={{ paddingLeft: '8px', maxWidth: '1200px', margin: '0 auto' }} mb={0}>
          <Toolbar bg="transparent" mr={2} css={{ label: 'toolbar' }}>
            <NavLink
              onClick={() => push('/')}
              pl={[4]}
              ml={[-3]}
              color={'white'}
              fontSize={[5, '40px', 6]}
              css={{ display: 'block', fontFamily: theme.fonts.display, fontWeight: 400 }}>
              <LogoText />
            </NavLink>
            <MobileMenu
              handleClick={this.handleClick}
              isOpen={this.state.isOpen}
              toggleModal={toggleModal}
              LinkText={LinkText}
              LogoText={LogoText}
            />
            <div className={flipDisplay}>
              <MenuLink children={'Docs'} toggleModal={toggleModal} />
              <MenuLink children={'Pricing'} toggleModal={toggleModal} />
              <MenuLink children={'About'} toggleModal={toggleModal} />
              <MenuLink children={'Sign In'} toggleModal={toggleModal} />
              <NavLink onClick={toggleModal} mr={[-3, 0]} fontSize={[3, 3]}>
                <Button
                  children="Try Now"
                  fontSize={3}
                  bg={theme.colors.oranges[1]}
                  className={buttonStyle}
                  mt={2}
                  py={3}
                />
              </NavLink>
            </div>
          </Toolbar>
        </Column>
      </Row>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Header;
